import {createSlice} from '@reduxjs/toolkit';

export const appSlice = createSlice({
    name:'app',
    initialState:{
        code: undefined,
        client: undefined,
        token: undefined,
        files: [],
        values:{},
        queryfiles: [],
        skip: 0,
        limit: 5,
        bottom: false,
        loading: false
    },
    reducers:{
        setCode: (state, action)=>{
            state.code = action.payload;
        },
        setClient: (state, action)=>{
            state.client = action.client;
        },
        setToken: (state, action)=>{
            state.token = action.payload;
        },
        setLoading: (state, action)=>{
            state.loading = action.payload;      
        },
        filesRequested:(state, action)=>{

        },
        editFile: (state, action)=>{
            for(let i = 0; i < state.files.length; i++){
                if(state.files[i]._id == action.payload.file._id){
                    state.files[i].info = action.payload.file.info;
                    let copy = JSON.parse(JSON.stringify(Object.assign({}, state.files[i])));
                    state.files.splice(i, 1, copy);
                    //Tee tähän sama mikä on alla, niin johon alkaa toimimaan.
                    let found = false;
                    for(let s = 0; s < state.values["recent"].length; s++){
                        if(state.values["recent"][s]._id == action.payload.file._id){
                            state.values["recent"][s].info = action.payload.file.info
                            found = true;
                            break;
                        }
                    }
                    if(!found){
                        let dt = new Date(state.files[i].createdAt*1000);
                        dt.setHours(0);
                        dt.setMinutes(0);
                        dt.setSeconds(0);
                        let datestring = dt.getDate()+"."+(dt.getMonth()+1)+"."+dt.getFullYear();
                        if(state.values[datestring]){
                            for(let j = 0; j < state.values[datestring].length; j++){
                                if(state.values[datestring][j]._id == action.payload.file._id){
                                    state.values[datestring][j].info = action.payload.file.info;
                                    break;
                                }
                            }
                        }
                    }
                    break;
                }
            }
        },
        deleteFile: (state, action)=>{
            for(let i = 0; i < state.files.length; i++){
                if(state.files[i]._id == action.payload._id){
                    let found = false;
                    for(let s = 0; s < state.values["recent"].length; s++){
                        if(state.values["recent"][s]._id == action.payload._id){
                            state.values["recent"].splice(s, 1);
                            found = true;
                            break;
                        }
                    }
                    if(!found){
                        let dt = new Date(state.files[i].createdAt*1000);
                        dt.setHours(0);
                        dt.setMinutes(0);
                        dt.setSeconds(0);
                        let datestring = dt.getDate()+"."+(dt.getMonth()+1)+"."+dt.getFullYear();
                        if(state.values[datestring]){
                            for(let j = 0; j < state.values[datestring].length; j++){
                                if(state.values[datestring][j]._id == action.payload._id){
                                    state.values[datestring].splice(j, 1);
                                    break;
                                }
                            }
                        }
                    }
  
                    state.files.splice(i, 1);
                    break;
                }
            }
        },
        resetFiles: (state, action)=>{
            state.files = [];
            state.values = {
                recent: []
            };
            state.bottom = false;
            state.skip = 0;
        },
        filesReceived:(state, action)=>{
            state.files = action.payload;
            state.loading = false;
        },
        filesSearched:(state, action)=>{
            state.queryfiles = action.payload;
        },
        fileRequestFailed:(state, action)=>{
            state.loading = false;
        },
        filesAdded:(state, action)=>{
            //Lisätään uudet aineistot tiedostot-listaan.
            state.files.unshift(...action.payload);
        },
        filesAppend:(state, action)=>{
            state.files = state.files.concat(action.payload);
        },
        removeFile:(state, action)=>{
            for(let i = 0; i < state.files.length; i++){
                if(state.files[i]._id == action.payload._id){
                    state.files.splice(i, 1);
                    return;
                }
            }
        },
        valuesReceived: (state, action)=>{
            if(action.payload.length < state.limit){
                state.bottom = true;
            }
            state.files = state.files.concat(action.payload);
            let values = {
                recent:[]
            }
            state.values = values;
            let time_first = 0;
            let time_current = 0;
            let datestring = "";
            let dt;
            for(let i = 0; i < state.files.length; i++){
                if(i == 0){
                    time_first = state.files[i].createdAt;
                    values.recent.push(state.files[i]);
                }else{
                    if(time_first == state.files[i].createdAt){
                        values.recent.push(state.files[i]);
                    }else{
                        if(dt != undefined && Math.floor(dt.getTime()/1000) <= state.files[i].createdAt && state.files[i].createdAt <= (Math.floor(dt.getTime()/1000)+(60*60*24))){
                            values[datestring].push(state.files[i]);
                        }else{
                            time_current = state.files[i].createdAt;
                            dt = new Date(state.files[i].createdAt*1000);
                            dt.setHours(0);
                            dt.setMinutes(0);
                            dt.setSeconds(0);
                            datestring = dt.getDate()+"."+(dt.getMonth()+1)+"."+dt.getFullYear();
                            values[datestring] = [state.files[i]];
                        }
                    }
                }
            }
            state.skip += state.files.length;
            state.values = values;
            //state.values = values;
        },
        addValues: (state, action)=>{
            let files = action.payload.files;
            if(action.payload.isnew){
                //Tehdään viimeisimmiksi uudet ja siirretään edelliset viimeisimmät muihin päivämääriin.
                let recent = JSON.parse(JSON.stringify(state.values.recent));
                state.values.recent = files;

                let datestring = "";
                let dt;
                for(let i = 0; i < recent.length; i++){
                        dt = new Date(recent[i].createdAt*1000);
                        datestring = dt.getDate()+"."+(dt.getMonth()+1)+"."+dt.getFullYear();
                        if(state.values[datestring]){
                            state.values[datestring].push(recent[i]);
                        }else{
                            state.values[datestring] = [recent[i]];
                        }
                }
            }else{
                let datestring = "";
                let dt;
                for(let i = 0; i < files.length; i++){
                    dt = new Date(files[i].createdAt*1000);
                    datestring = dt.getDate()+"."+(dt.getMonth()+1)+"."+dt.getFullYear();
                    if(state.values[datestring]){
                        state.values[datestring].push(files[i]);
                    }else{
                        state.values[datestring] = [files[i]];
                    }
                }
            }

        }
    }
});

export const {setToken, setLoading, filesRequested, filesReceived, fileRequestFailed, filesAdded, valuesReceived, addValues, filesAppend, editFile, deleteFile, filesSearched, resetFiles} = appSlice.actions;

export default appSlice.reducer;