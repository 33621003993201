import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTimes, faPlus} from '@fortawesome/free-solid-svg-icons';

import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';




function FileModal(props){

    const fileref = React.createRef();

    const inforef = React.createRef();

    let [files, setFiles] = useState([]);

    const onClose = event => {
        props.onCloseModal();
    };


    const sendFiles = event =>{
        if(props.onSendFiles && fileref.current.files.length > 0){
            props.onSendFiles(fileref.current.files, inforef.current.value);
            onClose();
        }
    }

    function filesExist(fs){
        if(props.onFilesExist){
            props.onFilesExist(fs);
        }
    }

    function AddFile(){
        fileref.current.click();
    }

    function FilesChanged(e){
        files = setFiles(Array.from(fileref.current.files));
        let arr = [];
        for(let i = 0; i < fileref.current.files.length; i++){
            arr.push(fileref.current.files[i].name);
        }
        filesExist(arr);
    }

    function ClearFiles(){
        fileref.current.value = "";
        setFiles([]);
    }

    return <div className="modal">
        <div className="modal-close">
        <FontAwesomeIcon icon={faTimes} onClick={onClose}/>
        </div>
        <div className="modal-content">
            <div className="subtitle">Lisää tiedostoja</div>
            {files.length > 0 &&
                <div className="lmc-button white" style={{float:"right"}} onClick={ClearFiles}>
                    Tyhjennä
                </div>
            }

            <div className="lmc-button gold" onClick={AddFile}>
                <FontAwesomeIcon icon={faPlus}/>

            </div>
            <input type="file" style={{display: "none"}} ref={fileref} onChange={FilesChanged} multiple></input>
            <div className="error-message red-text">{props.fileerror}</div>
            <div className="selected-files">
                {files.map((file)=>(
                    <div className="selected-file white" key={file.name}>{file.name}</div>
                ))}
            </div>
            <div className="smalltitle">Lisätietoa</div>
            <textarea className="textarea" spellcheck="false" placeholder="Tähän voit laittaa lisätietoa koskien lisättyjä aineistoja, esimerkiksi voimassaoloaikoja tms." ref={inforef}></textarea>
        </div>
        <div className="modal-footer">
            <button className="lmc-button gold" onClick={sendFiles}>Lataa</button>
            <button className="lmc-button white" onClick={onClose}>Peruuta</button>
        </div>
    </div>;
}


export default FileModal;