
import React from 'react';



export default class TitleBar extends React.Component{
  render(){
    return (
      <div className="lmc-title">
        <img src="cloud-logo-dark.svg"/>
      </div>
    );
  }
}
