import React, {useState, useRef} from 'react';
import ReactDOM from 'react-dom/client';


import {useSelector, useDispatch} from 'react-redux';

import FileGrid from './file-grid';
import FileModal from './widgets/file-modal';

import {ApiService} from './services/api-service';

import {ToastContainer, toast} from 'react-toast';

import {filesAdded, valuesReceived, addValues, filesAppend, filesSearched, resetFiles, setLoading} from './features/appSlice';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowDown} from '@fortawesome/free-solid-svg-icons'




function Timeline(){
    const files = useSelector((state)=> state.app.files);
    const values = useSelector((state)=> state.app.values);
    const limit = useSelector((state)=> state.app.limit);
    const skip = useSelector((state)=> state.app.skip);
    const bottom = useSelector((state)=> state.app.bottom);
    const queryfiles = useSelector((state)=> state.app.queryfiles);

    const search = useRef(0);

    let api = new ApiService();
    const dispatch = useDispatch();

    let now = new Date();

    let [showfilemodal, setShowfilemodal] = useState(false);

    let [fileerror, setFileerror] = useState(undefined);

    const [helper, setHelper] = useState(false);



    let [searchQuery, setSearchQuery] = useState("");

    function forceUpdate(){
      setHelper(!helper);
    }

    function ShowFileModal(){
      setFileerror(undefined);
      setShowfilemodal(!showfilemodal);
    }

    function FilesExist(files){
      api.exists(files).then((res)=>{
        if(res.length > 0 ){
          let s = "Tiedostonimet varattu: ";
          for(let i = 0; i < res.length; i++){
            s+= res[i].name;
            if(i != res.length-1){
              s+=", ";
            }
          }
          setFileerror(s);
          /*toast(s, {
            backgroundColor: "#fefefe",
            color: "black",
            zIndex: 11
          });*/
        }
      });
    }

    function formatDate(dt){
      return dt.getDate()+"."+(dt.getMonth()+1)+"."+dt.getFullYear();
    }

    function formatDateTime(dt){
      let minutes = dt.getMinutes();
      let hours = dt.getHours();
      return (hours < 10?"0"+hours:hours)+":"+(minutes < 10?"0"+minutes:minutes)+" "+ dt.getDate()+"."+(dt.getMonth()+1)+"."+dt.getFullYear();
    }

    function SendFiles(fls, info){
      dispatch(setLoading(true));
      api.sendFiles(fls, info).then((res)=>{
        dispatch(filesAdded(res));
        dispatch(addValues({files:res, isnew: true}));
        setTimeout(()=>{
          dispatch(setLoading(false));
          forceUpdate();
        }, 200);
      });
    }

    function LoadMore(){
      dispatch(setLoading(true));
      api.getFiles(limit, skip).then((res)=>{
        dispatch(valuesReceived(res));
        setTimeout(()=>{
          dispatch(setLoading(false));
        }, 200);
      });
    }

    function Search(){
      dispatch(setLoading(true));
      let str = search.current.value;
      setTimeout(()=>{
        if(str == search.current.value){
          setSearchQuery(search.current.value);
          if(str.length == 0){
            dispatch(filesSearched([]));
            dispatch(resetFiles([]));
            api.getFiles(limit, 0).then((res)=>{
              dispatch(valuesReceived(res));
              setTimeout(()=>{
                dispatch(setLoading(false));
              }, 200);
            });
          }else{
            api.searchFiles(str).then((res)=>{
              dispatch(filesSearched(res));
              setTimeout(()=>{
                dispatch(setLoading(false));
              }, 200);
            });
          }

        }else{

        }
      }, 400);
    }

    return(
        <div className="main-container">
        <section id="timeline" className="timeline-outer">
        <div className="search-box"><input type="search" placeholder="Hae kansiosta" onChange={Search} ref={search}/></div>
            <div className="actions">
                <div className="lmc-button gold" onClick={ShowFileModal}>Lisää tiedostoja</div>
            </div>

            {searchQuery.length <= 0 &&

              <div className="container" id="content" style={{borderRadius: "50px"}}>
              <div className="row">
                <div className="col s12 m12 l12">
                  <ul className="timeline">
                    {Object.keys(values).map((key, index)=>{
                      return (
                        <li className="event" data-date={key == 'recent'?'Viimeksi lisätyt':key == formatDate(now)?'Tänään':key}>
                        <h3>{key == 'recent'?'Viimeksi lisätyt ':key == formatDate(now)?'Tänään':key} <span>{key == 'recent' && values[key].length > 0?formatDateTime(new Date(values[key][0].createdAt*1000)):''}</span></h3>
                        <FileGrid files={values[key]} />
                      </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              </div>

            }

            {searchQuery.length > 0 && queryfiles.length > 0 &&
              <div className="container" id="content" style={{borderRadius: "50px"}}>
              <div className="row">
                <div className="col s12 m12 l12">

                        <div className="grid">
                            <FileGrid files={queryfiles} />
                        </div>
                </div>
              </div>
              </div>
            }

          {bottom == false &&
          <div className="lmc-button white" onClick={LoadMore}>
            <FontAwesomeIcon icon={faArrowDown} />
          </div>
          }
        </section>
        {showfilemodal == true &&
                  <FileModal onCloseModal={ShowFileModal} onSendFiles={SendFiles} onFilesExist={FilesExist} fileerror={fileerror}></FileModal>
        }
        <br/>
        <ToastContainer position="bottom-center" />
      
      </div>
    );
}



export default Timeline;