
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTimes, faPlus} from '@fortawesome/free-solid-svg-icons';

function InfoModal(props){

    const onClose = event => {
        props.onCloseModal();
    };


    return (
        <div className="modal">
                            <div className="modal-close">
                    <FontAwesomeIcon icon={faTimes} onClick={onClose}/>
                </div>
            <div className="modal-content">

                <div className="subtitle">Info</div>
                <p>
                    Tämä on LEDiMediaCloudista jaettu kansio. Tämän sivun kautta voit toimittaa haluamasi aineistot kumppanisi käyttöön. Voit myös seurata täältä aineistojen lisäyksen historiaa.<br/><br/>TÄRKEÄÄ! Mikäli käytät
                    tätä jaettua kansiota julkisella laitteella (laitteen käyttäjiä on enemmän kuin yksi), niin tyhjennä selaimen historia kun olet valmis. Älä ikinä jaa linkkiä tähän kansioon kenellekään, kenelle kansion hallinta
                    ja tarkastelu ei kuulu. Jos epäilet että linkki on päätynyt vääriin käsiin, ilmoita asiasta kansion haltijalle uuden linkin luomiseksi.<br/><br/>
                    Ongelmatilanteissa voit olla yhteydessä LEDiMediaan. Otamme myös vastaan kehitysideoita.

                    <br/><br/>Helpdesk / Tekninen tuki
                    <br/>Juha Hokkanen,
                    <br/>+358 44 243 9858
                    <br/>juha.hokkanen@ledimedia.fi<br/><br/>

                </p>
                <p>
                LEDiMedia Oy on kotimainen digitaalisen näyttöviestinnän valtakunnallinen ammattilainen. Toimitamme laadukkaita näyttö- ja av-ratkaisuja toimialasta riippumatta. Toimintamme kattaa lisäksi live videotuotannon,
                sekä näyttöjen vuokrauksen messuista stadion konsertteihin. Asiakkaamme toimivat teollisuuden, kaupan ja palveluiden, kulttuurin ja urheilun sekä julkisen sektorin toimialoilla. Valikoimastamme löydät
                kaikenkokoiset LED-screenit, mainospylonit, LED- ja NEON valomainokset, sekä opaste- / infonäytöt kosketusnäytöllä tai ilman. Palvelumme kattaa laitteiden lisäksi aina myös asennuksen, huollon ja tukipalvelut.
                Oman hallintaohjelmistomme LEDiMediaCloudin kautta erilaisten media-aineistojen hallinnointi onnistuu mistä päin Suomea tahansa. Kiitos mielenkiinnosta. Ole meihin yhteydessä jos yrityksessäsi on tarve mille tahansa
                edellä mainituista tuotteista tai palveluista.
                </p>
            </div>
        </div>
    );
}


export default InfoModal;