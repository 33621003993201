import store from '../features/store'

export class ApiService{
    constructor(){
        //Haetaan tunnistautumiseen tarkoitetut parameterit.
        let params = new URLSearchParams(window.location.search);
        this.code = params.get('code');
        this.client = params.get('client');
        this.production = true;
        //this.url = this.production?"https://beta.ledimediacloud.fi/api":"http://localhost:4000";
        this.url = this.production?"https://api.cloud.ledimedia.fi":"http://localhost:4000";

    }

    login(code){
        return fetch(this.url+"/"+this.client+"/file-api/login",{
            method:"post",
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json'
            },
            body: JSON.stringify(
                {code: code}
            )
        }).then(rep => rep.json()).then((data)=>{
            if(data.success === true){
                return data.token;
            }else{
                return undefined;
            }
        });
    }

    getFiles(limit, skip){
        return fetch(this.url+"/"+this.client+"/file-api/files?sharecode="+this.code+"&limit="+limit+"&skip="+skip).then(rep => rep.json()).then((data)=>{
            if(data.success === true){
                let files = data.files;
                return files;
            }else{
                return undefined;
            }
        });
    }
    searchFiles(q){
        return fetch(this.url+"/"+this.client+"/file-api/files/search?sharecode="+this.code+"&q="+q).then(rep => rep.json()).then((data)=>{
            if(data.success == true){
                return data.files;
            }else{
                return undefined;
            }
        });
    }
    getThumbPath(file){
        if(file.type == "video"){
            //return "/video.png";
            return this.url+"/"+this.client+"/file-api/video-thumb/"+file.name+"?sharecode="+this.code;
        }
        return this.url+"/"+this.client+"/file-api/thumb/"+file.name+"?sharecode="+this.code;
    }
    getFilePath(file){
        return this.url+"/"+this.client+"/file-api/file/"+file.name+"?sharecode="+this.code;
    }
    exists(filenames){
        return fetch(this.url+"/"+this.client+"/file-api/exists?sharecode="+this.code, {method:"POST", headers:{"Content-Type":"application/json"}, body:JSON.stringify({filenames: filenames})}).then(rep => rep.json()).then((data)=>{
            if(data.success == true){
                return data.existing;
            }else{
                return [];
            }
        });
    }
    sendFiles(files, info){
        const formData = new FormData();
        for(let i = 0; i < files.length; i++){
            let file = files[i];
            formData.append("files", file);
        }
        if(info && info.length > 0){
            formData.append("info", info);
        }
        const req = new Request(this.url+"/"+this.client+"/file-api/files?sharecode="+this.code, {method: "POST", body: formData});
        return fetch(req).then(rep => rep.json()).then((data)=>{
            if(data.success == true){
                return data.medias;
            }else{
                return [];
            }
        });
    }
    saveFile(file){
        return fetch(this.url+"/"+this.client+"/file-api/files?sharecode="+this.code, {
            method: "PUT",
            body: JSON.stringify(file),
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json'
            }
        
        }).then(rep => rep.json()).then((data)=>{
            if(data.success == true){
                return true;
            }else{
                return false;
            }
        });
    }
    deleteFile(file){
        return fetch(this.url+"/"+this.client+"/file-api/files?sharecode="+this.code, {
            method:"DELETE",
            body: JSON.stringify(file),
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json'
            }
        }).then(rep => rep.json()).then((data)=>{
            if(data.success == true){
                return true;
            }else{
                return false;
            }
        });
    }
    getDownloadLink(file){
        return this.url+"/"+this.client+"/file-api/download/"+file._id+"/"+file.name+"?sharecode="+this.code;
    }
}