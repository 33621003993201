import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import Home from './home/home.js';
import './index.css';

import store from './home/features/store'

import { Provider } from 'react-redux'

import {ApiService} from './home/services/api-service';

import { useSelector, useDispatch } from 'react-redux';

import {filesReceived, valuesReceived, setLoading} from './home/features/appSlice';


function Index(props){
  const dispatch = useDispatch();
  const limit = useSelector((state)=> state.app.limit);

  const api = new ApiService();

  useEffect(()=>{
    dispatch(setLoading(true));
    api.getFiles(limit, 0).then((res)=>{
      //dispatch(filesReceived(res));
      dispatch(valuesReceived(res));
      setTimeout(()=>{
        dispatch(setLoading(false));
      }, 1000);
    });
    return () => {
      console.log('component will unmount')
    }
  }, [])

  /*useEffect(()=>{
    console.log("component did update");
  })*/

  const onClickHandler = () => {

  }

  return (
    <div>
    <Home />
    </div>
  );
}


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Index />
  </Provider>
);
