import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';


import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faCircleQuestion, faPlus} from '@fortawesome/free-solid-svg-icons'

import TitleBar from './titlebar';
import FileGrid from './file-grid';
import Timeline from './timeline';

import {useSelector, useDispatch} from 'react-redux';
import {setToken} from './features/appSlice';

import InfoModal from './widgets/info-modal';

function Home(){
  let [showInfo, setShowInfo] = useState(false);
  const token = useSelector((state)=> state.app.token);
  const loading = useSelector((state)=> state.app.loading);
  const dispatch = useDispatch();
  const updateCode = (e) => dispatch({
    type:'set-code',
  });
  return(
  <div>
    <TitleBar />
    <div className="lmc-info" onClick={()=>setShowInfo(true)}>
      <FontAwesomeIcon icon={faCircleQuestion} />
    </div>
    <Timeline />
    <h1>{token}</h1>
    {showInfo == true && 
      <InfoModal onCloseModal={()=> setShowInfo(!showInfo)}></InfoModal>
    }
    {loading == true &&
    <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    }
    </div>
    );
  
}


export default Home;
