import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTrash, faEye, faGear, faEdit, faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import {ApiService} from './services/api-service';
import FileOptionsModal from './widgets/file-options-modal';

function FileGrid(props){
    let api = new ApiService();

    let [showFileOptions, setShowFileOptions] = useState(false);
    let [selectedFile, setSelectedFile] = useState(undefined);

    function GetThumbPath(file){
      return api.getThumbPath(file);
    }

    function SelectFile(file){
      setSelectedFile(file);
      setShowFileOptions(true);
    }

    function CloseOptionsModal(){
      setShowFileOptions(false);
      setSelectedFile(undefined);
    }



    return( 
      <div className="lmc-grid" >
       {props.files.map((file)=>(
         <div className="lmc-grid-item">
          <div className="lmc-grid-item-image" onClick={()=>SelectFile(file)}>
            <div className="lmc-thumb">
              <img src={GetThumbPath(file)} />
              {file.type == "video" &&
                <div className="thumb-play-icon">
                  <FontAwesomeIcon icon={faPlayCircle} />
                </div>
              }

            </div>
          </div>
          <div className="lmc-grid-item-name flex-parent">
          <div className="flex-start">
            <input className="passive-input" type="text" max="26" value={file.name} readOnly spellCheck="false"></input>
          </div>
          <div className="flex-end">
            <FontAwesomeIcon icon={faEdit} onClick={()=>SelectFile(file)} />
          </div>
          </div>
         </div>
       ))}
       {showFileOptions == true && 
              <FileOptionsModal onCloseModal={CloseOptionsModal} file={selectedFile}></FileOptionsModal>
       }
      </div>);

}

export default FileGrid;
