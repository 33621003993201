
import {ApiService} from '../services/api-service';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTimes, faPlus} from '@fortawesome/free-solid-svg-icons';
import {useSelector, useDispatch} from 'react-redux';

import React, {useState, useRef, useEffect} from 'react';

import {editFile, deleteFile, setLoading} from '../features/appSlice';


function FileOptionsModal(props){
    const api = new ApiService();
    const dispatch = useDispatch();

    const [info, setInfo] = useState(undefined);
    const [deleteStatus, setDeleteStatus] = useState(0);

    const infoTextRef = useRef(null);

    const downloadlink = useRef(null);

    const onClose = event => {
        props.onCloseModal();
    };

    function onSaveFile(){
        let file ={
            _id: props.file._id,
            info: infoTextRef.current.value
        };
        dispatch(setLoading(true));
        api.saveFile(file).then((res)=>{
            if(res){
                setInfo(file.info?file.info:"");
                infoTextRef.current = info;
                dispatch(editFile({file:file}));
            }
            setTimeout(()=>{
                dispatch(setLoading(false));
            }, 500)
        });
    };

    function formatDateTime(seconds){
        let dt = new Date(seconds*1000);
        let minutes = dt.getMinutes();
        let hours = dt.getHours();
        return (hours < 10?"0"+hours:hours)+":"+(minutes < 10?"0"+minutes:minutes)+" "+ dt.getDate()+"."+(dt.getMonth()+1)+"."+dt.getFullYear();
    }

    function onDeleteFile(){
        if(deleteStatus == 1){
            api.deleteFile(props.file).then((res)=>{
                if(res){
                    dispatch(deleteFile(props.file));
                    props.onCloseModal();
                }
            });
        }
        setDeleteStatus(1);
    }

    function clickLink(){
        downloadlink.current.click();
    }

    useEffect(()=>{
        setInfo(props.file.info?props.file.info:"");
        infoTextRef.current.value = props.file.info?props.file.info:"";
    }, []);


    return (
        <div className="modal" style={{overflowY:"scroll"}}>
            <div className="modal-close">
                <FontAwesomeIcon icon={faTimes} onClick={onClose}/>
            </div>
            <div className="modal-content">

            <div className="file-container">
                <div className="file-info">
                    <div className="subtitle">{props.file.name+ " - "+formatDateTime(props.file.createdAt)}</div>
                    <textarea className="textarea" ref={infoTextRef} spellCheck="false"></textarea>
                    <div className="lmcbtn gold" onClick={onSaveFile}>Tallenna tiedot</div>
                    <div className="lmcbtn gold" onClick={clickLink}>Lataa tiedosto</div>
                    {deleteStatus == 0 && 
                           <div className="lmcbtn redbg"  onClick={onDeleteFile}>Poista tiedosto</div>
                    }
                    {deleteStatus == 1 && 
                            <div className="level">
                                <div className="lmcbtn redbg" style={{width:"30%"}}  onClick={onDeleteFile}>Poista</div>
                                <div className="lmcbtn white" style={{width:"30%"}}  onClick={()=>{setDeleteStatus(0)}}>Peruuta</div>
                            </div>
                    }
                    <a href={api.getDownloadLink(props.file)} download={props.file.name} style={{textDecoration: "none", display: "none"}} ref={downloadlink} ></a>
                </div>
                <div className="file-preview">
                    {props.file.type == "image" &&
                        <img src={api.getFilePath(props.file)}></img>
                    }
                    {props.file.type == "video" &&
                        <video src={api.getFilePath(props.file)} controls></video>
                    }
                </div>
            </div>
            </div>
        </div>
    )
}


export default FileOptionsModal;